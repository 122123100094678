.bcpl-section-1 {
    height: 100vh;
    width: 100vw;
    position: relative;
    background-size: cover;
    background-position: right;
  }
  
  .bcpl-button {
    background-color: black;
    border-radius: 2px;
    padding: 10px 25px;
    text-align: center;
    color: white;
    border: 2px solid transparent;
  }
  
  .bcpl-button:hover {
    background-color: #525252;
  }
  
  .bcpl-container {
    bottom: 10vh;
  }
  
  .bcpl-row-1 {
    align-content: end;
    margin-top: 50px;
    width: 100%;
  }
  
  .bcpl-s1-text {
    text-align: right;
    margin-top: 15px;
    font-size: 1.35vw;
  }
  
  .bcpl-s1-text-image {
    width: 80%;
    margin-left: 20%;
    font-family: 'Kanit';
    font-size: 1.35vw;
    margin-top: 7vh;
    margin-bottom: 3.5vh;
  }
  
  .bcpl-s1-button {
    margin-top: 25px;
    float: right;
  }
  
  