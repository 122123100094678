@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@700&family=Kanit&family=Russo+One&display=swap");
/* font-family: 'Fredoka', sans-serif;
font-family: 'Kanit', sans-serif;
font-family: 'Russo One', sans-serif; */

html {
	scroll-snap-type: y proximity;
}
body {
	margin: 0;
	font-family: Kanit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	/* margin: 0 5% 0 5%; */
	text-decoration: none;
	background-size: cover;
	width: 100%;
	height: 100%;
	/* height: fit-content; */
	font-size: 50px;
}

.header {
	position: absolute;
	display: flex;
	height: 10vh;
	width: 100%;
	overflow-x: hidden;
	/* display: none; */
}

.header-title {
	position: relative;
	top: 1vh;
	/* left: 1vw; */
	margin-left: 3vw;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 5px;
	width: 15vw;
	height: 10%;
	font-family: "Russo One";
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 36px;
	text-align: center;
	white-space: nowrap;
	color: #ffffff;
	overflow: visible;
}

.header-title.header-titleM {
	position: inherit;
	justify-content: space-evenly;
	margin: 2.5% 0 0 0;
	width: 35vw;
	font-size: 4vw;
}

.header-title.header-titleM img {
	width: 7.5vw;
	height: auto;
}

.header-title img {
	width: 48px;
	height: 48px;
	/* padding-right: 5%; */
}
.header-title span {
}

.anchors {
	position: fixed;
	top: 0;
	right: 10vw;
	display: inline-flex;
	justify-content: space-around;
	align-items: center;
	max-width: 50%;
	font-size: 0.3em;
	z-index: 500;
	--bs-link-hover-color: white;
}

.anchors .firstChild {
	border-radius: 0px 0px 0px 20px;
	border-width: 0px 1px 0px 0px;
}

.anchors .lastSecondChild {
	/* padding: 5% 25% 0% 4%; */
	/* position: sticky; */
	right: 25%;
	/* margin-right: 0%; */
}

.anchors .lastChild {
	position: fixed;
	max-width: 6vw;
	max-height: 2vh;
	padding-bottom: 30px;
	top: -2vh;
	right: 0;
	color: white;
	text-decoration: none;
	background: linear-gradient(180deg, #5247c8 0%, #de19f3 100%);
	box-shadow: 4px 7px 19px -8px rgba(0, 0, 0, 0.99);
	border-radius: 0px 0px 0px 36px;
	flex: 2 0 auto;
	white-space: nowrap;
}
/* For each  */
.anchors > * {
	position: relative;
	top: 0;
	width: 8vw;
	height: 5vh;
	text-align: center;
	padding: 2.5% 4% 0% 4%;
	background: #181818;
	box-sizing: content-box;
	box-shadow: 4px 7px 19px -8px rgba(0, 0, 0, 0.99);
	border: solid #292929;
	border-width: 0px 1px 0px 1px;
	text-decoration: none;
	color: white;
}

.logo {
	/* width: calc(100% - var(--anchor_width)); */
	/* flex: 3 0 auto; */
}

.content-container {
}
.content-container div {
	scroll-snap-align: start;
}

.content-container section {
	scroll-snap-align: start;
}

.section1 {
	/* margin-top: 5vh; */
	max-width: 100vw;
	/* max-height: 80vh; */
	height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: "Kanit", serif;
	overflow: hidden;
	box-shadow: inset 429px 30px 250px 97px rgba(12, 25, 32, 0.73), inset 83px 197px 250px 97px rgba(11, 27, 47, 0.58);
}

/* .vid {
	box-shadow: inset 429px 30px 250px 97px rgba(12, 25, 32, 0.73), inset 83px 197px 250px 97px rgba(11, 27, 47, 0.58);
}

.vid p {
	color: rgba(0, 0, 0, 0);
} */

.section1.section1M {
	box-shadow: inset 14px 5px 79px 3px rgba(12, 25, 32, 0.73), inset 34px -4px 79px 3px rgba(11, 27, 47, 0.58);
}
.section1 video {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100% !important;
	/* height: auto !important; */
	width: 100%;
	max-height: 100vh;
	height: 100vh;
	object-fit: cover;
	z-index: -200;
}

.section1-container {
	display: flex;
	width: 55vw;
	height: 70%;
	margin-left: 2.5vw;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	row-gap: 2vh;

}

.section1-container.m {
	row-gap: 0;
	justify-content: space-evenly;
	width: 80vw;
	margin-top: 0;
}
.section1-container > * {
	max-width: 100%;
	text-align: left;
}

.section1-container header {
	font-family: "Fredoka";
	font-style: normal;
	font-weight: 700;
	font-size: 2em;
	color: white;
	margin-bottom: -2.5%;
}

.section1-container.m header {
	font-size: 15vw;
}
.section1-container-info {
	font-family: "Kanit";
	font-style: normal;
	font-weight: 400;
	font-size: 1.3vw;
	color: #ffffff;
}

.section1-container-info.m {
	max-width: 90%;
	width: 90%;
	font-size: 4vw;
	font-weight: 200;
}

.section1-container-alert {
	font-family: "Kanit";
	font-style: normal;
	font-weight: 700;
	font-size: 0.8em;
	text-align: left;
	max-width: 100%;
	width: 100%;
	/* max-height: 10%; */
	color: #ffffff;
}

.section1-container-alert.m {
	font-size: 5vw;
}

.section1-container-countdown {
	font-family: "Kanit";
	font-style: normal;
	font-weight: 700;
	font-size: 0.4em;
	/* line-height: 45px; */
	text-align: center;
	letter-spacing: 0.1em;
	width: 30%;
	color: #dcdbf7;
	position: relative;
	top: 5vh;
}

.section1-container-countdown.m {
	top: 0vh;
	font-size: 2.5vw;
	margin-top: 0;
	width: 40%;
	display: flex;
	flex-direction: column;
}

.section1-container-countdown:first-child {
	width: 100%;
	max-width: 100%;
}

.section1-container-countdown-timers {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.section1-container-countdown-timers.m {
	width: 100%;
	height: 50%;
	overflow: hidden;
}
.section1-container-countdown-timers.m span {
	background-size: 80%;
}

.section1-container-countdown-timers span {
	background: url(../public/images/timer.png);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	/* background: #dedede; */
	width: 45px;
	height: 55px;
	/* border-radius: 15px; */
}
.section1-container-countdown-colon.m img {
	position: relative;
	right: -0.25px;
	height: 12.5px;
	width: auto;
}

.section1-container-countdown-colon {
	/* background: url("../public/images/colon.png"); */
	/* background-size: 100%; */
	/* background-repeat: no-repeat; */
	/* width: 58px;
  height: 56px; */
}
.section1-container-form {
	width: 70%;
	height: 40px;
	/*margin-top: -20px;*/
	display: flex;
	align-items: center;
}

.section1-container-form.m {
	min-height: 150px;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 5px;
	/* display: block; */
	width: 100%;
}

.section1-container-form-text {
	/* position: relative; */
	top: 2px;
	background: url("../public/images/emailBG.png");
	background-position: center;
	background-size: 100% 100px;
	background-repeat: no-repeat;
	border: 0;
	width: 65%;
	height: 100%;
	font-family: "Kanit";
	font-style: normal;
	font-weight: 400;
	font-size: 0.3em;
	padding-left: 10px;
	overflow: visible;
}

.section1-container-form-text.m {
	width: 80%;
	font-size: 3.5vw;
}

.section1-container-form-submit {
	background: url("../public/images/registerBG.png");

	font-family: "Kanit";
	font-style: normal;
	font-weight: 700;
	font-size: 0.3em;
	/* line-height: 30px; */

	color: #ffffff;
	padding: 0;
	/* border: 1px solid #000000; */
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position: center;
	border: 0;
	width: 35%;
	height: 100%;

	text-align: center;
	cursor: pointer;
}

#get-it-on-google-play-button {
	width: auto;
	height: 50px;
}

#get-it-on-testflight {
	width: auto;
	height: 50px;
}

#get-it-on-google-play-button-m {
	width: auto;
	height: 50px;
}

#get-it-on-testflight-m {
	width: auto;
	height: 50px;
	margin-top: -40px;
}
/* 
#get-it-on-google-play-button-m {
	width: 57%;
	height: auto;
}

#get-it-on-testflight-m {
	width: 50%;
	height: auto;
	margin-top: -30px;
} */

/* #get-it-on-google-play-button {
	width: 100%;
	height: 100px;
	margin-left: -15px;
}

#get-it-on-testflight {
	width: 100%;
	height: 68px;
} */

.section1-container-form-submit.m {
	font-size: 3.5vw;
	vertical-align: middle;
	background: none;
	background-color: rgb(242, 22, 246);
	border: none 2px;
	border-radius: 7.5px;
	padding: 5px;
	margin-top: 10px;
}

.mail-set {
	position: relative;
	font-size: 2vh;
	color: white;
	left: 0;
}

.mail-set.m {
	left: 1vw;
	font-size: 1vh;
}

.ellipseShape {
	position: absolute;
	left: -40vw;
	top: 0;
	height: 100%;
	width: 100vw;
	transform: scale(1, 2);
	z-index: -199;
	overflow: hidden;
}

.ellipseShape.ellipseShapeM {
	transform: scale(1, 1.3);

	height: 90%;
	top: 0vh;
	left: -10vw;
}

.section2 {
	height: 100vh;
}

.section2 video {
	position: relative;
	top: 0;
	left: 0;
	max-width: 100% !important;
	width: 100%;
	max-height: 100vh;
	height: 100vh;
	object-fit: cover;
	z-index: 200;
}

.section2-overlay {
	position: relative;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0.5) 32%,
		rgba(0, 0, 0, 0) 46%
	);
	z-index: 300;
}

.side-scroller {
	position: fixed;
	width: 5%;
	top: 35%;
	height: 250px;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
	font-size: medium;
	/* background: url(../public/images/sideScroller.png);
  background-repeat: no-repeat;
  background-size: 100% 100vh; */
	z-index: 400;
}

.side-scroller a {
}

.side-scroller a img {
	margin-top: -25px;
	width: 35px;
	height: 35px;
}

.side-scroller-img {
	position: fixed;
	top: 31.5%;
	width: 50px;
	height: 300px;
	right: 0;
	z-index: 399;
}

.partner-container {
	width: 100%;
	height: 75vh;
	text-align: center;
	background-color: rgb(14, 14, 27);
	font-family: Kanit;
	color: rgb(255, 255, 255);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}


.partner-container h1 {
	font-family: Russo One;
	/* margin: 5vh 0px; */
	margin: 0;
	padding: 10px 0;
	width: 100%;
	font-size: 3.25vh;

}


.partner-container-text {
	font-size: 3vh;
	font-family: Russo One;
	color: rgb(255, 255, 255);
}

.partner-img-container1 {
	display: flex;
	width: 55%;
	height: 13vh;
	justify-content: space-evenly;
}

.partner-img-container1.m {
	display: grid;
	grid-template-columns: 1fr 1fr ;
	place-items: center;
	width: 70%;
	height: auto;
}
.partner-img-container1.m img {
	width: 70%
}

.partner-img-container1 img {
	width: 10vw;
	object-fit: contain;
}

.partner-img-container2 {
	display: flex;
	width: 55%;
	height: 13vh;
	justify-content: space-evenly;
	padding-top: 20px;
}

.partner-img-container2.m {
	display: grid;
	grid-template-columns: 1fr 1fr ;
	place-items: center;
	width: 70%;
	height: auto;
}
.partner-img-container2.m img {
	width: 80%
}

.partner-img-container2 img {
	width: 8vw;
	object-fit: contain;
}

.partner-img-container3 {
	display: flex;
	width: 40%;
	justify-content: space-evenly;
}
.partner-img-container3.m{
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 0px;
	place-items: center;
	height: auto;
	width: 70%;
}
.partner-img-container3 img {
	width: 7vw;
	object-fit: contain;
}

.partner-img-container3.m img {
	width: 70%;
}

.partner-imgs {
	position: relative;
}

.partner-left-img {
	position: absolute;
	top: 15vh;
	bottom: 0;
	left: -50vw;
	width: 15vw;
}

.partner-right-img {
	position: absolute;
	top: 15vh;
	bottom: 0;
	right: -50vw;
	width: 15vw;
}

.teaser-container {
	width: 100%;
	/* height: 75vh; */
	text-align: center;
	background-color: #0B0B1E;
	font-family: Kanit;
	color: rgb(255, 255, 255);
	/* display: flex;
	flex-direction: column;
	justify-content: space-evenly; */
	align-items: center;
}

.teaser-container.m {
	width: 100%;
	height: 50vh;
	/* height: 75vh; */
	text-align: center;
	background-color: #0B0B1E;
	font-family: Kanit;
	color: rgb(255, 255, 255);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

footer {
	font-family: Kanit;
	font-weight: 300;
	font-size: 2.25vh;
	background-color: rgb(19, 19, 32);
	color: white;
	width: 100%;
	height: 100%;
	position: relative;
	bottom: 0;
	text-align: center;
}
